/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import io from "socket.io-client"
import Echo from "laravel-echo"
import { useStore } from "lib/store"

const SocketWrapper = ({ children }) => {
  const { user_data } = useStore((state) => state)
  const [socket, setSocket] = React.useState(null)
  const [state, setState] = React.useState("")
  const { setToast, setHighBid } = useStore((state) => state)
  React.useEffect(() => {
    if (user_data) {
      console.log(`message.user.${user_data?.id}`)
      console.log("socket running...")
      let echo = new Echo({
        broadcaster: "socket.io",
        host: "https://stg-market-cms.karafuru.io",
        client: io,
      })
      setSocket(echo)
      echo.channel("message.admin").listen("MessageAdmin", (args) => {
        console.log(args, "ADMIN")
        alert(JSON.stringify(args))
        setState(JSON.stringify(args))
      })
      //prettier-ignore
      echo
        .channel(`message.user.${user_data?.id}`)
        .listen("MessageUser", (args) => {
          let { message, highest,  } = args?.message;
          setHighBid(highest?.amount)
          setToast({isToast: true, toastState: { message: message, status: "danger" }})
        })
    }
  }, [user_data])

  return <>{children}</>
}

export default SocketWrapper
