export const PLAYAREA_LINK = "/playarea"
export const INBOX_LINK = "/dashboard/inbox"
export const AUCTION_LINK = "/auction"
export const DASHBOARD_LINK = "/dashboard"
export const RAFFLE_LINK = "/dashboard/raffle"
export const SALES_LINK = "/dashboard/sales"
export const TRANSACTIONS_LINK = "/dashboard/transactions"
export const INFO_CENTER_LINK = "/info-center"
export const SHOP_LINK = "/"
export const NFT_LINK = "/nft"
export const TERMS_LINK = "/terms-conditions"
