/* eslint-disable @next/next/no-img-element */
import React from "react"
import { fadeInOut } from "src/variants/variants"
import { useMenuStore } from "store"
import imgClose from "public/img/common/img-close.png"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "components/anti/link/link"
import { useScrollAnim } from "components/hooks/hooks"
import { useRouter } from "next/router"
import { Portal } from "react-portal"
import Router from "next/router"

const MobileMenu = () => {
  const [trigger, anim] = useScrollAnim()
  const router = useRouter()
  const { mobileMenu, setMobileMenu } = useMenuStore((state) => state)

  const [show, setShow] = React.useState(false)

  const handleClose = () => {
    setMobileMenu(false)
  }

  const handleNavigate = (url) => {
    handleClose()
    router.push(url)
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        {mobileMenu && (
          <motion.div
            variants={fadeInOut()}
            key={"key-mobile-menu"}
            initial="initial"
            animate="animate"
            exit="exit"
            className="mobile-menu"
          >
            <div ref={trigger} className="mobile-menu-header">
              <img
                src={imgClose}
                alt="img-close"
                className="img-fluid img-close"
                width="35px"
                onClick={handleClose}
              />
            </div>
            <div className="mobile-menu-content">
              <div className={`mobile-menu__item ${anim(1)}`}>
                <div
                  onClick={() => handleNavigate("/")}
                  className={`mobile-menu__link ${
                    router.asPath === "/" ? "active" : ""
                  }`}
                >
                  Token Shop
                </div>
              </div>
              <div className={`mobile-menu__item ${anim(2)}`}>
                <div
                  onClick={() => handleNavigate("/playarea")}
                  className={`mobile-menu__link ${
                    router.asPath === "/playarea" ? "active" : ""
                  }`}
                >
                  Play Area
                </div>
              </div>
              <div className={`mobile-menu__item ${anim(3)}`}>
                <div
                  to="/info-center"
                  onClick={() => handleNavigate("/info-center")}
                  className={`mobile-menu__link ${
                    router.asPath === "/info-center" ? "active" : ""
                  }`}
                >
                  Info Center
                </div>
              </div>
            </div>
            <div className="mobile-menu-footer"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default MobileMenu
