// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .footer-custom.scss.

// ========================================================================================================================================
// Footer
// ========================================================================================================================================

import React from "react"

import { Link } from "components/anti"

export const Footer = () => {
  return (
    <footer>
      <div className="container py-4 text-center">
        Copyright {new Date().getFullYear()} Karafuru. All rights reserved.
        {/* <div className="row row-0">
          <div className="col-md-6 col-left">
            © {new Date().getFullYear()} Karafuru. All rights reserved.
          </div>
          <div className="col-md-6 col-right">
            <div className="link-wrapper">
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </footer>
  )
}
