/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios"
import Cookies from "js-cookie"
import React, { useState } from "react"

import { Modal, Button } from "components/anti"
import useConnectWallet from "components/hooks/useConnectWallet"
import { injected, walletconnect } from "components/utils/connecters"
import {
  MetaMaskWording,
  generalErrorWording,
  UnsupportedChainIdError,
} from "components/global/toast"
import { useStore } from "lib/store"
import { MAIN_URL, HeadersToken } from "lib"
import { useWeb3React } from "@web3-react/core"
import { generalErrorMessageMapper } from "components/utils/helpers"

export const ConnectWallet = ({ id, show, hide, isGame }) => {
  const [loading, setLoading] = useState(null)
  const [isConnecting, setIsConnecting] = useState(false)

  const { user_data, setToast } = useStore((state) => state)
  const { account, onConnect } = useConnectWallet({
    isUsingHooks: loading !== "metamask",
  })
  const { library } = useWeb3React()

  const handleLoginRegister = async (account) => {
    try {
      //Register
      const nonce = await axios.get(
        MAIN_URL(`/auth/nonce/register?wallet_address=${account}`)
      )
      const signer = library.getSigner()
      const signature = await signer.signMessage(nonce?.data?.data?.nonce)
      let RegisterRes = await axios.post(
        MAIN_URL("/auth/register"),
        {
          wallet_address: account,
          signature,
        },
        { headers: HeadersToken() }
      )
      console.log(RegisterRes?.data)
      if (RegisterRes?.data?.success) {
        Cookies.set(
          process.env.NEXT_PUBLIC_ACCESS_TOKEN,
          RegisterRes?.data?.data?.token
        )
        window.location.reload()
      }
      Cookies.set(process.env.NEXT_PUBLIC_WALLET_ADDRESS, account)
    } catch (error) {
      setLoading(null)
      setIsConnecting(false)
      setToast({
        isToast: true,
        toastState: {
          message: generalErrorMessageMapper(error),
          status: "danger",
        },
      })
    }
  }

  const handleWallet = async (connected) => {
    setIsConnecting(true)
    setLoading("walletconnect")
    await onConnect(connected)
  }

  const handleConnect = async () => {
    setIsConnecting(true)
    setLoading("metamask")
    if (window.ethereum) {
      try {
        if (
          window.ethereum.networkVersion !== process.env.NEXT_PUBLIC_CHAIN_ID
        ) {
          throw new Error(UnsupportedChainIdError)
        }
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        })
        setTimeout(() => {
          if (accounts.length && library) {
            setIsConnecting(false)
            console.log("ACCOUNTS ==>", accounts)
            handleLoginRegister(accounts[0])
          } else {
            setIsConnecting(false)
            setLoading(null)
            console.log("LIBRARY NOT FOUND")
            if (library) {
              setToast({
                isToast: true,
                toastState: { message: generalErrorWording, status: "danger" },
              })
            }
          }
        }, 3500)
      } catch (error) {
        setLoading(null)
        setToast({
          isToast: true,
          toastState: { message: error?.message, status: "danger" },
        })
      }
    } else {
      setLoading(null)
      setToast({
        isToast: true,
        toastState: { message: MetaMaskWording, status: "danger" },
      })
      hide()
    }
  }

  React.useEffect(() => {
    let token = Cookies.get(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
    if (!user_data && !token && isConnecting) {
      if (account && library) {
        console.log("shoudldnt be here")
        handleLoginRegister(account)
        setIsConnecting(false)
      }
    }
  }, [account, library])

  return (
    <Modal
      id={id}
      isShowing={show}
      hide={() => hide()}
      className="common-modal modal-connectwallet modal-bottom"
    >
      <h1 className="title">Connect Wallet</h1>
      <p className={`text ${!isGame ? "mb-0" : ""}`}>
        Connect your wallet to{" "}
        {!isGame ? "join the Karafuru Playground." : "play furu run"}
      </p>
      {!isGame ? (
        <p className="text">
          (You need to have a Karafuru NFT Genesis in the wallet you connect
          with.)
        </p>
      ) : null}
      <Button
        loader
        onClick={() => handleConnect(injected)}
        className={loading === "metamask" ? "loading" : ""}
      >
        METAMASK
      </Button>
      <Button
        loader
        onClick={() => handleWallet(walletconnect)}
        className={`mt-3 ${loading === "walletconnect" ? "loading" : ""}`}
      >
        WALLET CONNECT
      </Button>
    </Modal>
  )
}
