import { generalErrorWording } from "components/global/toast"

export const truncate = (
  string,
  length,
  type = "middle",
  separator = "..."
) => {
  if (string?.length <= length) return string

  switch (type) {
    case "middle":
      var sepLen = separator.length,
        charsToShow = length - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2)
      return (
        string?.substr(0, frontChars) +
        separator +
        string?.substr(string?.length - backChars)
      )
    case "front":
      var sepLen = separator.length,
        charsToShow = length - sepLen,
        backChars = Math.floor(length)
      return separator + string?.substr(string?.length - backChars)
    case "back":
      var sepLen = separator.length,
        charsToShow = length - sepLen,
        frontChars = Math.ceil(charsToShow)
      return string?.substr(0, frontChars) + separator
    default:
      var sepLen = separator.length,
        charsToShow = length - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2)
      return (
        string?.substr(0, frontChars) +
        separator +
        string?.substr(string?.length - backChars)
      )
  }
}

export const videoRegex =
  /((?:https?(?:%3A%2F%2F|:\/\/))(?:www\.)?(?:\S+)(?:%2F|\/)(?:(?!\.(?:mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))[^\/])*\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg))(?!\/|\.[a-z]{1,3})/

export const countItem = (arr, key, condition) =>
  +arr.filter((item) => item[key] === condition)?.length

export const generalErrorMessageMapper = (error) => {
  return (
    error?.response?.data?.data?.message ||
    error?.response?.data?.message ||
    error?.message ||
    generalErrorWording
  )
}
