import { subDays } from "date-fns"
import create from "zustand"

const defaultSelected = {
  from: new Date(),
  to: subDays(new Date(), 30),
}

export const useTransactionStore = create((set) => ({
  range: defaultSelected,
  type: "All",
  setRange: (range) => set(() => ({ range })),
  setType: (type) => set(() => ({ type })),
}))
