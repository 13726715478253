const transition = { duration: 1, ease: [0.77, 0, 0.175, 1] } // ease-in-out-quart

export const fadeInOut = (duration = 0.5, delay = 0) => {
  return {
    initial: {
      opacity: [1, 0],
    },
    animate: {
      opacity: [0, 1],
      transition: {
        ...transition,
        duration,
        delay,
      },
    },
    exit: {
      opacity: [1, 0],
      transition: {
        ...transition,
        duration,
        delay,
      },
    },
  }
}

export const fadeInOut2 = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,

    transition: {
      delay: 0,
      ...transition,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ...transition,
    },
  },
}
export const fadeIn = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,

    transition: {
      delay: 0.1,
      ...transition,
    },
  },
  exit: {
    display: "none",
    transition: {
      ...transition,
    },
  },
}
export const fadeIn2 = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,

    transition: {
      delay: 0.1,
      ...transition,
    },
  },
  exit: {
    display: "none",
    transition: {
      ...transition,
    },
  },
}
