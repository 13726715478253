import { Link } from "components/anti"
import { toast as toaster } from "react-hot-toast"

export const useToast = () => {
  const toast = {
    error: ({ message = "", link }) => {
      toaster((t) => (
        <span className="toast-error">
          {message}
          {link && (
            <>
              <br />
              <br />
              <Link to={link.url}>{link.text}</Link>
            </>
          )}
          <button onClick={() => toaster.dismiss(t.id)}>
            <i className="air ai-times" />
          </button>
        </span>
      ))
    },
    info: ({ message = "", link }) => {
      toaster((t) => (
        <span className="toast-info">
          {message}
          {link && (
            <>
              <br />
              <br />
              <Link to={link.url}>{link.text}</Link>
            </>
          )}
          <button onClick={() => toaster.dismiss(t.id)}>
            <i className="air ai-times" />
          </button>
        </span>
      ))
    },
    success: ({ message = "", link }) => {
      toaster(() => (
        <span className="toast-success">
          {message}
          {link && (
            <>
              <br />
              <br />
              <Link to={link.url}>{link.text}</Link>
            </>
          )}
          <button onClick={() => toaster.dismiss(t.id)}>
            <i className="air ai-times" />
          </button>
        </span>
      ))
    },
  }

  return { toast }
}
