// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ==========================================================================
// Forms - Select
// ==========================================================================

import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import { Modal } from "components/anti/modal/modal"
import { useWindowSize } from "rooks"

export const Select = ({
  id,
  name,
  variant,
  label,
  value,
  placeholder,
  formText,
  onChange,
  onBlur,
  error,
  touched,
  iconRight,
  iconLeft,
  floatingLabel,
  options,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  className,
  classNamePrefix,
  wrapperClassName,
  labelClassName,
  inputClassName,
  forwardRef,
  onInputChange,
  openMenuOnFocus,
  onFocus,
  isDrawerOnMobile,
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [modal, setModal] = useState(false)
  const { innerWidth } = useWindowSize()

  const fieldVariant = () => {
    if (variant === "underline") {
      return "field-underline"
    } else {
      return "field-basic"
    }
  }

  const handleChange = (arg) => {
    setCurrentValue(arg)
    onChange(id, arg)
  }

  const handleBlur = () => {
    if (onBlur) return onBlur(id, true)
  }

  const handleClickSelect = () => {
    if (innerWidth < 768) {
      setModal(`modal-${label.toLowerCase()}`)
    }
  }

  return (
    <div
      className={`field ${fieldVariant()}
        ${floatingLabel ? "field-floating" : ""}
        ${className} ${isDrawerOnMobile ? `field-drawer-mobile` : ``} `}
    >
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      )}
      <div
        className={`form-select-wrapper rs
          ${iconRight ? "ic-right" : ""}
          ${iconLeft ? "ic-left" : ""}
          ${wrapperClassName}`}
        onClick={isDrawerOnMobile ? handleClickSelect : () => {}}
      >
        <ReactSelect
          id={id}
          className={`form-select ${variant} ${className} ${
            isDrawerOnMobile ? `form-drawer-mobile` : ``
          }`}
          // hideSelectedOptions={true}
          classNamePrefix={classNamePrefix}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          options={options}
          value={currentValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          ref={forwardRef}
          onInputChange={onInputChange ? (e) => onInputChange(e) : () => {}}
          openMenuOnFocus={openMenuOnFocus}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          // onFocus={onFocus ? () => onFocus() : () => {}}
        />
        {floatingLabel && (
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
        )}
      </div>

      {isDrawerOnMobile && (
        <Modal
          id={`modal-${label.toLowerCase()}`}
          isShowing={modal}
          hide={() => {
            setModal(null)
          }}
          className="modal-drawer modal-select"
        >
          <h4 className="mb-3">{label}</h4>

          <div className="select-drawer">
            {options?.map((item, i) => (
              <DrawerItem
                {...item}
                setCurrentValue={handleChange}
                setModal={setModal}
                key={`${i}-drawer-${id}`}
              />
            ))}
          </div>
        </Modal>
      )}

      {!!error && touched && <div className="form-text">{error}</div>}
      {formText && <div className="form-text">{formText}</div>}
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDrawerOnMobile: PropTypes.bool,
}

Select.defaultProps = {
  variant: "basic-single",
  className: "",
  classNamePrefix: "select",
  isSearchable: false,
  placeholder: "Choose...",
  isDrawerOnMobile: false,
}

function DrawerItem({ label, value, setCurrentValue, setModal }) {
  return (
    <div
      className="select-drawer__item"
      onClick={() => {
        setCurrentValue({ label, value })
        setModal(null)
      }}
    >
      <span>{label}</span>
      <i className="air ai-chevron-right"></i>
    </div>
  )
}
