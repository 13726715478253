import { NFT_LINK } from "components/page_url"
import nookies from "nookies"
import Cookies from "js-cookie"

export const MAIN_URL = (url) => {
  return `${process.env.NEXT_PUBLIC_ENDPOINT}${url}`
}

export const ProductDetailLink = (item) => {
  let { type, id, name } = item
  let parseName = name.toLowerCase().split(" ").join("-")
  return `${NFT_LINK}/${type}/${id}/${parseName}`
}

export const HeadersToken = (ctx) => {
  let token
  if (!ctx) token = Cookies.get(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
  else token = nookies.get(ctx)[process.env.NEXT_PUBLIC_ACCESS_TOKEN]

  return {
    Authorization: `Bearer ${token}`,
  }
}
