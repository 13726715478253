/* eslint-disable @next/next/no-img-element */
import React from "react"
import { Link } from "components/anti/link/link"
import { useCallback, useRef, useState, useEffect } from "react"
import imgToken from "assets/img/common/img_token.png"
import icChevronDown from "public/img/icon/ic-chevron-down.png"
import { useOutsideClick } from "rooks"
import { useStore } from "lib/store"
import { ConnectWallet } from "components/global/modal-connect-wallet"
import Cookies from "js-cookie"

import {
  DASHBOARD_LINK,
  TRANSACTIONS_LINK,
  INBOX_LINK,
} from "components/page_url"
import { truncate } from "components/utils/helpers"
import { useRouter } from "next/router"
const NavAccount = ({ user_data }) => {
  const ref = useRef()
  const [show, setShow] = useState(false)

  const handleClickNavAccount = () => {
    setShow(!show)
  }

  const Router = useRouter()

  const handleDisconnect = () => {
    Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
    Cookies.remove(process.env.NEXT_PUBLIC_WALLET_ADDRESS)
    window.location.reload()
  }

  useOutsideClick(ref, () => {
    if (show) {
      setShow(false)
    }
  })

  const handleNavigate = (url) => {
    handleClickNavAccount()
    Router.push(url)
  }
  return (
    <div className="position-relative" ref={ref}>
      <div className="nav-item nav-account" onClick={handleClickNavAccount}>
        <div>
          <span className="nav-account__address">
            {truncate(user_data?.wallet_address, 16)}
          </span>
          <div className="nav-account__token">
            <img
              src={imgToken}
              alt="img-token"
              className="img-fluid"
              width="20px"
            />
            <strong>{user_data?.token}</strong> <small>tokens</small>
          </div>
        </div>
        <div className="nav-account__chevron">
          {/* <img
            src={icChevronDown}
            alt="ic-chevron"
            className="img-fluid"
            width="17.5px"
          /> */}
          <i className="air ai-chevron-down"></i>
        </div>
      </div>

      <div
        // ref={ref}
        className={`nav-account-dropdown hide ${show ? `show` : ``}`}
      >
        <div className="nav-account-dropdown-list">
          <div className="nav-acount-dropdown__item">
            <div onClick={() => handleNavigate("/dashboard")}>Dashboard</div>
          </div>
          <div className="nav-acount-dropdown__item">
            <div onClick={() => handleNavigate("/dashboard/transactions")}>
              Transaction History
            </div>
          </div>
          <div className="nav-acount-dropdown__item">
            <div onClick={() => handleNavigate("/dashboard/inbox")}>Inbox</div>
          </div>
          <div
            className="nav-acount-dropdown__item disconnect"
            onClick={handleDisconnect}
          >
            <div>Disconnect Wallet</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DesktopMenu = ({ data, navExpand, openConnect }) => {
  //Modal
  const modalId = "login-register-modal"
  const [showModal, setShowModal] = React.useState(null)

  const router = useRouter()

  const { user_data, user_coin } = useStore((state) => state)

  const activeMenu = useCallback(
    ({ url }) => {
      if (router?.pathname == "/" && url == "/shop") {
        return "active"
      }

      return router?.pathname == url ? `active` : ``
    },
    [router]
  )

  return (
    <div className={`desktop-menu d-none d-${navExpand}-flex`}>
      <ConnectWallet
        id={modalId}
        show={showModal}
        hide={() => setShowModal(null)}
      />
      <ul className="navbar-nav ml-auto">
        {data.map((item, i) => {
          return (
            <li key={`${i}-nav-item-desktop`} className="nav-item">
              {item?.isLogin ? (
                <>
                  {user_data ? (
                    <Link
                      className={`nav-link ${activeMenu(item)}`}
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <div
                      onClick={() => openConnect()}
                      className={`nav-link ${activeMenu(item)}`}
                    >
                      {item.title}
                    </div>
                  )}
                </>
              ) : (
                <Link className={`nav-link ${activeMenu(item)}`} to={item.url}>
                  {item.title}
                </Link>
              )}
            </li>
          )
        })}
        {user_data ? (
          <NavAccount user_data={user_data} />
        ) : (
          <li className="nav-item" onClick={() => setShowModal(modalId)}>
            <div className="nav-link">MY ACCOUNT</div>
          </li>
        )}
      </ul>
    </div>
  )
}

export default DesktopMenu
