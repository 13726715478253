import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import Web3 from "web3"
import Web3Modal from "web3modal"

const POLLING_INTERVAL = 12000
const CHAIN_ID = +process.env.NEXT_PUBLIC_CHAIN_ID || 1
const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID

export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
})

export const walletconnect = new WalletConnectConnector({
  infuraId: INFURA_ID,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

export const resetWalletConnector = (connector) => {
  if (
    connector &&
    connector instanceof WalletConnectConnector &&
    connector.walletConnectProvider?.wc?.uri
  ) {
    connector.walletConnectProvider = undefined
  }
}

export const disconnected = (connect) => {
  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions: {
      walletconnect: {
        package: connect,
        options: {
          // Mikko's test key - don't copy as your mileage may vary
          infuraId: INFURA_ID,
        },
      },
    }, // required
  })

  return web3Modal
}
