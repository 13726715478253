// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Hooks
// ========================================================================================================================================

import { useState, useEffect, useCallback, useLayoutEffect } from "react"
import moment from "moment-timezone"
import { useInView } from "react-intersection-observer"
import { Loader } from "@googlemaps/js-api-loader"

// ==========================================================================
// Count Down
// ==========================================================================

const prependZero = (number, limit) => {
  return number < limit ? `0${number}` : number
}

export const useCountdown = ({ startDate, endDate }) => {
  const [isStart, setStart] = useState(false)
  const [isEnded, setEnded] = useState(false)
  const [countdown, setCountdown] = useState({
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const eventStart = moment(startDate)
      const eventEnded = moment(endDate)
      const now = moment()

      const duration = moment.duration(eventStart.diff(now))
      const days = prependZero(duration.asDays(), 10)
      const hours = prependZero(duration.hours(), 10)
      const minutes = prependZero(duration.minutes(), 10)
      const seconds = prependZero(duration.seconds(), 10)

      if (eventEnded.valueOf() < now.valueOf()) {
        setEnded(true)
      } else if (eventStart.valueOf() < now.valueOf()) {
        setStart(true)
      } else {
        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        })
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return [isStart, isEnded, countdown]
}

// ==========================================================================
// Scroll Anim
// ==========================================================================

/**
 *
 * @param {*} animation
 * @returns
 *
 * e.g .fadeInUp, .fadeInDown, etc.
 * Please check base/animations.scss for complete list of animations
 */

export const useScrollAnim = (animation = "fadeInUp") => {
  const [ref, inView] = useInView({
    threshold: [0.125, 0.5, 0.75],
    triggerOnce: true,
  })

  const [animate, setAnimate] = useState(() => {
    return {
      text: `scroll-${animation}`,
      hasSet: false,
    }
  })

  const anim = useCallback(
    (delay, replaceAnim = null) => {
      if (replaceAnim) {
        return animate.hasSet
          ? `scroll-${replaceAnim} ${replaceAnim}` + ` d${delay}`
          : `scroll-${replaceAnim}`
      } else {
        return animate.hasSet
          ? `${animate.text} ${animation}` + ` d${delay}`
          : animate.text
      }
    },
    [animate]
  )

  useEffect(() => {
    if (inView) {
      setAnimate((prevState) => {
        return {
          ...prevState,
          hasSet: true,
        }
      })
    }
  }, [inView])

  return [ref, anim]
}

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect

// ==========================================================================
// Google Maps, Google Place Search, Google Helpers
// ==========================================================================
export const useGoogleMaps = (
  elementId = "map",
  options = { geocoder: false }
) => {
  const [gMaps, setGMaps] = useState()
  const [marker, setMarker] = useState()
  const [geocoder, setGeocoder] = useState()

  const init = (location) => {
    const loader = new Loader({
      apiKey: process.env.ANTI_GMAPS_APIKEY,
      version: "weekly",
      libraries: ["places", "geometry"],
      region: "ID",
    })

    loader.load().then((google) => {
      let defaultLocation = new google.maps.LatLng(-6.195002, 106.823052)
      if (location) {
        defaultLocation = new google.maps.LatLng(...location)
      }
      const configuration = {
        center: defaultLocation,
        zoom: 18,
        mapTypeControl: false,
        scaleControl: false,
        zoomControl: false,
        // gestureHandling: "none",
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        region: "ID",
        // backgroundColor: "#FFF",
        // disableDefaultUI: false,
        // styles: [
        //   {
        //     featureType: "water",
        //     elementType: "geometry",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     featureType: "landscape",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     featureType: "road",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     featureType: "administrative",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     featureType: "poi",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     featureType: "administrative",
        //     stylers: [{ visibility: "off" }],
        //   },
        //   {
        //     elementType: "labels",
        //     stylers: [{ visibility: "off" }],
        //   },
        // ],
      }
      const map = new google.maps.Map(
        document.getElementById(elementId),
        configuration
      )
      const marker = new google.maps.Marker({
        position: defaultLocation,
        map,
        title: "Outlet Here",
        // icon: {
        //   url: [image_url],
        //   scaledSize: new google.maps.Size(20, 23),
        //   origin: new google.maps.Point(0, 0),
        //   anchor: new google.maps.Point(10, 25),
        // },
      })
      if (options.geocoder) {
        const geocoder = new google.maps.Geocoder()
        setGeocoder(geocoder)
      }
      setGMaps(map)
      setMarker(marker)
    })
  }
  return { init, gMaps, marker, geocoder }
}

export const useGoogleHelper = () => {
  // prettier-ignore
  const geocoderHelper = (response, select = 0) => {
    // response could be get from response of geocoder.geocode
    const address = response.results[select].address_components
    const street = address.find((el) => el.types.includes("route"))
    const number = address.find((el) => el.types.includes("street_number"))
    const neighbourhood = address.find((el) => el.types.includes("administrative_area_level_7")) // rt
    const hamlet = address.find((el) => el.types.includes("administrative_area_level_6"))        //rw
    const urbanvillage = address.find((el) => el.types.includes("administrative_area_level_4"))  // kelurahan
    const subdistrict = address.find((el) => el.types.includes("administrative_area_level_3"))   // kecamatan
    const city = address.find((el) => el.types.includes("administrative_area_level_2"))
    const province = address.find((el) => el.types.includes("administrative_area_level_1"))
    const country = address.find((el) => el.types.includes("country"))
    const zipcode = address.find((el) => el.types.includes("postal_code"))
    return {
      number,
      hamlet,
      urbanvillage,
      city,
      province,
      neighbourhood,
      subdistrict,
      zipcode,
      country,
      street,
    }
  }
  return { geocoderHelper }
}

export const useGooglePlaceSearch = () => {
  const [gList, setGList] = useState([])
  const [gError, setGError] = useState("")

  const crawler = (autocomplete, val) => {
    autocomplete.getPlacePredictions(
      {
        input: val,
        componentRestrictions: { country: "id" },
      },
      (gPredictions, status) => {
        setGError("")
        // const splitValue = val.split(",")
        let predictions
        if (gPredictions && gPredictions.length > 0) {
          predictions = [
            // ====== commented code below is used for display what user's type (see: XL Satu)
            // {
            //   description: val,
            //   structured_formatting: {
            //     main_text: splitValue[0],
            //     secondary_text: splitValue.splice(1).join(","),
            //   },
            // },
            ...gPredictions,
          ]
        }
        if (
          status != google.maps.places.PlacesServiceStatus.OK ||
          !gPredictions
        ) {
          setGList([])
        } else {
          setGList(predictions)
        }
      }
    )
  }

  const onChangeGInput = (val) => {
    if (google.maps.places) {
      const autocomplete = new google.maps.places.AutocompleteService()
      crawler(autocomplete, val)
    } else {
      const loader = new Loader({
        apiKey: process.env.XLCENTER_GMAPS_APIKEY,
        version: "weekly",
        libraries: ["places", "geometry"],
      })
      loader
        .load()
        .then((google) => {
          const autocomplete = new google.maps.places.AutocompleteService()
          crawler(autocomplete, val)
        })
        .catch((err) => setGError(err.message))
    }
  }

  const onChangeGMapCenter = (mapId, placeId) => {
    let defaultLocation = new google.maps.LatLng(-6.195002, 106.823052)
    const configuration = {
      center: defaultLocation,
      zoom: 18,
      mapTypeControl: false,
      scaleControl: false,
      zoomControl: false,
      // gestureHandling: "none",
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      region: "ID",
      // backgroundColor: "#FFF",
      // disableDefaultUI: false,
      // styles: [
      //   {
      //     featureType: "water",
      //     elementType: "geometry",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     featureType: "landscape",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     featureType: "road",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     featureType: "administrative",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     featureType: "poi",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     featureType: "administrative",
      //     stylers: [{ visibility: "off" }],
      //   },
      //   {
      //     elementType: "labels",
      //     stylers: [{ visibility: "off" }],
      //   },
      // ],
    }
    const maps = new google.maps.Map(
      document.getElementById(mapId),
      configuration
    )
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ placeId }).then((e) => {
      const position = new google.maps.LatLng(
        e.results[0].geometry.location.lat(),
        e.results[0].geometry.location.lng()
      )
      const marker = new google.maps.Marker({
        position: position,
        map: maps,
        title: "Outlet Here",
        icon: {
          url: pin,
          scaledSize: new google.maps.Size(20, 23),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10, 25),
        },
      })
      maps.setCenter(position)
    })
  }

  return { gList, onChangeGInput, gError, onChangeGMapCenter }
}
