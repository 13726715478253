/* eslint-disable @next/next/no-img-element */
import React from "react"
import Cookies from "js-cookie"
import { fadeInOut } from "src/variants/variants"
import { useMenuStore } from "store"
import imgClose from "public/img/common/img-close.png"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "components/anti/link/link"
import { useScrollAnim } from "components/hooks/hooks"
import imgToken from "assets/img/common/img_token.png"
import { useStore } from "lib/store"
import { truncate } from "components/utils/helpers"
import {
  DASHBOARD_LINK,
  INBOX_LINK,
  TRANSACTIONS_LINK,
} from "components/page_url"
import { useRouter } from "next/router"

const AccountMenu = ({ handleAccountMenu }) => {
  const [trigger, anim] = useScrollAnim()
  // const [accountMenu] = React.useState(true)
  const Router = useRouter()

  const { accountMenu, setAccountMenu } = useMenuStore((state) => state)
  const { user_data } = useStore((state) => state)

  const [state, setState] = React.useState({
    address: truncate(user_data?.wallet_address, 20),
    coin: user_data?.token,
  })

  const handleClose = () => {
    setAccountMenu(false)
  }

  const handleNavigate = (url) => {
    // handleAccountMenu()
    handleClose()
    Router.push(url)
  }

  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        {accountMenu && (
          <motion.div
            variants={fadeInOut()}
            key={"key-account-menu"}
            initial="initial"
            animate="animate"
            exit="exit"
            className="mobile-menu account-menu"
          >
            <div ref={trigger} className="mobile-menu-header">
              <img
                src={imgClose}
                alt="img-close"
                className="img-fluid img-close"
                width="32px"
                onClick={handleClose}
              />
            </div>
            <div className="mobile-menu-content">
              <div className={`mobile-menu__item ${anim(1)}`}>
                <span>MY ACCOUNT</span>
                <div className="box box-gray box-account">
                  <div className="box-content justify-content-between">
                    <strong>{state.address}</strong>
                    {/* <strong>0xb794f5ea0ba39494ce839...</strong> */}
                    <div className="d-flex align-items-center">
                      <img
                        src={imgToken}
                        alt="img-token"
                        className="img-fluid mr-2"
                        width="20px"
                      />
                      <span className="token">{state.coin}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleNavigate(DASHBOARD_LINK)}
                className={`mobile-menu__item ${anim(2)}`}
              >
                <div className="mobile-menu__link">Dashboard</div>
              </div>
              <div
                onClick={() => handleNavigate(TRANSACTIONS_LINK)}
                className={`mobile-menu__item ${anim(3)}`}
              >
                <div className="mobile-menu__link">Transaction History</div>
              </div>
              <div
                onClick={() => handleNavigate(INBOX_LINK)}
                className={`mobile-menu__item ${anim(4)}`}
              >
                <div className="mobile-menu__link">Inbox</div>
              </div>
              <div className={`mobile-menu__item ${anim(5)}`}>
                <div
                  onClick={() => {
                    Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
                    Cookies.remove(process.env.NEXT_PUBLIC_WALLET_ADDRESS)
                    window.location.reload()
                  }}
                  className="mobile-menu__link disconnect"
                >
                  Disconnect Account
                </div>
              </div>
            </div>
            <div className="mobile-menu-footer"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default AccountMenu
