/* eslint-disable @next/next/no-sync-scripts */
import React from "react"
import "assets/scss/main.scss"
import "react-day-picker/dist/style.css"
import { LoadingProvider } from "context/loading-context"
import Router from "next/router"
import App from "next/app"
import { Provider, useCreateStore, initializeStore } from "lib/store"
import TagManager from "react-gtm-module" //! For datalayer
import Toast from "components/global/toast"
import nookies from "nookies"
import { getInitialData } from "lib/get-initial-data"
import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactProvider } from "@web3-react/core"
import SocketWrapper from "components/socket-wrapper"
import Head from "next/head"

//!Loading at top screen
import nProgress from "nprogress"
import SEO from "components/seo"
import { CookieProvider } from "@reactivers/use-cookie"
nProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", (url) => nProgress.start())
Router.events.on("routeChangeComplete", () => nProgress.done())
Router.events.on("routeChangeError", () => nProgress.done())

const getLibrary = (provider) => {
  return new Web3Provider(provider)
}

function MyApp({ Component, pageProps, initialZustandState }) {
  //Utils **************************************************************************************//
  const createStore = useCreateStore(initialZustandState)
  //Utils **************************************************************************************//
  //React Operations **************************************************************************//
  React.useEffect(() => {
    if (TagManager.initialize) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER,
      })
    }
  }, [])
  //React Operations **************************************************************************//

  return (
    <>
      <SEO />
      <Web3ReactProvider getLibrary={getLibrary}>
        <CookieProvider>
          <Provider createStore={createStore}>
            <Head>
              {/* <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="0161ae49-9fab-4825-8dbe-4e12de30eef1";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
                }}
              ></script> */}
            </Head>
            <LoadingProvider>
              <SocketWrapper>
                <Toast />
                <Component {...pageProps} />
              </SocketWrapper>
            </LoadingProvider>
          </Provider>
        </CookieProvider>
      </Web3ReactProvider>
    </>
  )
}

export default MyApp

MyApp.getInitialProps = async (appContext) => {
  let appProps = await App.getInitialProps(appContext)
  const zustandStore = initializeStore()

  let accessToken = nookies.get(appContext?.ctx)[
    process.env.NEXT_PUBLIC_ACCESS_TOKEN
  ]
  if (accessToken) {
    let { data, errors } = await getInitialData(appContext?.ctx)
    if (errors) return { notFound: true }
    let { setState } = zustandStore
    setState({ user_data: data?.data, user_coin: data?.data?.token })
    return {
      ...appProps,
      initialZustandState: JSON.parse(JSON.stringify(zustandStore.getState())),
    }
  } else {
    return {
      ...appProps,
      initialZustandState: JSON.parse(JSON.stringify(zustandStore.getState())),
    }
  }
}
