// ░█▀▀▄ ░█▀▀▀█ 　 ░█▄─░█ ░█▀▀▀█ ▀▀█▀▀ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█─░█ ░█──░█ 　 ░█░█░█ ░█──░█ ─░█── 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█▄▄▀ ░█▄▄▄█ 　 ░█──▀█ ░█▄▄▄█ ─░█── 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be used flexibly. But if you REALLY MUST update edit the component,
// please add `-custom` on the change log file (e.g. `1.0.0-custom.md`) and log the changes

// ========================================================================================================================================
// Video
// ========================================================================================================================================

import React, { useState } from "react"
import PropTypes from "prop-types"
import Skeleton from "react-loading-skeleton"

export const Video = ({
  id,
  poster,
  videoSrc,
  autoPlay,
  loop,
  muted,
  playsInline,
  preload,
  type,
  className,
  useLoading,
  ratio
}) => {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <>
      {ratio ? (
        <div className={`video-wrapper ratio ${ratio}`}>
          {isLoading && useLoading ? (
            <Skeleton className="video-skeleton" />
          ) : null}
          <div className={`outer-content ${isLoading ? "d-none" : "d-block"}`}>
            <div className="inner-content">
              <video
                id={id}
                className={`video-js ${className}`}
                poster={poster}
                data-setup="{}"
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                preload={preload}
                playsInline={playsInline}
                onLoadedData={() => setIsLoading(false)}
              >
                <source id={id} src={videoSrc} type={type} />
              </video>
            </div>
          </div>
        </div>
      ) : (
        <div className="video-wrapper">
          {isLoading && useLoading ? (
            <Skeleton className="video-skeleton" />
          ) : null}
          <video
            id={id}
            className={`video-js ${className}`}
            poster={poster}
            data-setup="{}"
            autoPlay={autoPlay}
            loop={loop}
            muted={muted}
            preload={preload}
            playsInline={playsInline}
            onLoadedData={() => setIsLoading(false)}
          >
            <source id={id} src={videoSrc} type={type} />
          </video>
        </div>

      )}
    </>
  )
}

Video.propTypes = {
  id: PropTypes.string,
  poster: PropTypes.any,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  preload: PropTypes.string,
  playsInline: PropTypes.bool,
  className: PropTypes.string,
  useLoading: PropTypes.bool,
}

Video.defaultProps = {
  className: "",
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
  preload: "auto",
  type: "video/mp4",
  useLoading: false,
}
