import { useLayoutEffect } from "react"
import create from "zustand"
import createContext from "zustand/context"
import { initialValue, userAction } from "./states"
let store

const zustandContext = createContext()

export const Provider = zustandContext.Provider
// An example of how to get types
/** @type {import('zustand/index').UseStore<typeof initialState>} */
export const useStore = zustandContext.useStore

export const initializeStore = (preloadedState = {}) => {
  return create((set, get) => ({
    ...initialValue(),
    ...preloadedState,
    ...userAction(set),
  }))
}

export function useCreateStore(serverInitialState) {
  if (typeof window === "undefined") {
    return () => initializeStore(serverInitialState)
  }
  const isReusingStore = Boolean(store)
  store = store ?? initializeStore(serverInitialState)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (serverInitialState && isReusingStore) {
      store.setState(
        {
          ...store.getState(),
          ...serverInitialState,
        },
        true
      )
    }
  })

  return () => store
}
