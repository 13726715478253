import axios from "axios"
import { MAIN_URL, HeadersToken } from "lib"

export const getInitialData = async (ctx) => {
  try {
    let { data } = await axios.get(MAIN_URL("/account"), {
      headers: HeadersToken(ctx),
    })
    if (data) return { data }
    else return { data: null, errors: "Not found errors" }
  } catch (error) {
    return { errors: error }
  }
}
