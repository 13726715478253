export const initialValue = () => ({
  user_data: null,
  user_coin: 0,
  message: "Hello from zustand",
  isToast: false,
  toastHeight: false,
  toastState: {
    message: "",
    status: "",
  },
  toastTime: 5000,
  auctions: [],
  high_bid: null,
})

export const userAction = (set) => ({
  setMessage: (message) => set({ message: message }),
  setUserData: (values) => set({ user_data: values }),
  setToast: (payload) => {
    let { isToast, toastState } = payload
    set({ isToast, toastState })
  },
  setHighBid: (payload) => set({ high_bid: payload }),
  setUserCoin: (payload) => set({ user_coin: payload }),
})
