import React from "react"
import { useCountdown } from "lib/countdown-timer"

const Timer = ({ date, callback, labelClassName = "", isEnded }) => {
  const [days, hours, minutes, seconds] = useCountdown(date)
  React.useEffect(() => {
    if (days < 1 && hours < 1 && minutes < 1 && seconds < 1) callback(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, hours, minutes, seconds])

  return (
    <div className="information time m-0">
      <div className={`label ${labelClassName}`}>Ends In</div>
      <div className="value">
        {!isEnded ? (
          <>
            {days}D : {hours}H : {minutes < 10 ? "0" : ""}
            {minutes}M : {seconds?.length === 1 ? "0" : ""}
            {seconds}S
          </>
        ) : (
          <>-</>
        )}
      </div>
    </div>
  )
}

export const CardTimer = ({ date, type, isFinished }) => {
  const [days, hours, minutes, seconds] = useCountdown(date)
  const [isEnded, setIsEnded] = React.useState(false)
  React.useEffect(() => {
    if (days < 1 && hours < 1 && minutes < 1 && seconds < 1) setIsEnded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days, hours, minutes, seconds])

  return (
    <div className={`card-date ${isEnded && "ended"}`}>
      {!isEnded ? (
        <>
          <p className="label">ENDS IN</p>
          <p className="value">
            {days}D : {hours}H : {minutes < 10 ? "0" : ""}
            {minutes}M : {seconds < 10 ? "0" : ""}
            {seconds}S
          </p>
        </>
      ) : (
        <p className="label text-center">
          {type} has {isFinished ? "ended" : "closed"}
        </p>
      )}
      {/* {type === "raffle" ? (
          <p className="value">{moment(rawDate).format("ll")}</p>
        ) : (
          <p className="value">{date}</p>
        )} */}
    </div>
  )
}

export default Timer
