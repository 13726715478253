import create from "zustand"

export const useMenuStore = create((set) => ({
  mobileMenu: false,
  accountMenu: false,
  setMobileMenu: (mobileMenu) => set(() => ({ mobileMenu })),
  setAccountMenu: (accountMenu) => set(() => ({ accountMenu })),
}))

export const useGeneralStore = create((set) => ({
  activitySummary: "ongoing",
  setActivitySummary: (activitySummary) => set(() => ({ activitySummary })),
}))
