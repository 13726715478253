/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useStore } from "lib/store"
import { Link } from "components/anti"

export const MetaMaskWording =
  "No Ethereum browser extension detected, install Metamask on desktop or visit: "
export const UnsupportedChainIdError =
  "You're connected to an unsupported network."
export const Outbidded =
  "You are outbidded for NFT Karafuru Honorary #001 auction Bid Again"
export const insufficientFuruToken =
  "You don’t have enough Furu Token Stake NFT"
export const visitMarketplaceWording =
  "Sorry, you must have Karafuru genesis NFT to join activities here Visit Marketplace"
export const generalErrorWording = "Oops, something's wrong.."
export const SuccessAuctionWording = "Auction has submit"
export const NoAddressWording = "Please fill wallet address"

const Toast = () => {
  const { isToast, toastState, toastTime } = useStore((state) => state)
  const closeToast = useStore((state) => state.setToast)

  const [state, setState] = useState({
    message: null,
    status: null,
    url: null, //Object
  })

  const handleClickClose = () => {
    closeToast({
      isToast: false,
      toastState: { ...toastState },
    })
  }

  useEffect(() => {
    let { message, type } = toastState
    let wording = message
    let status = type
    let url = null

    if (type === "outbid") {
    }

    if (message === MetaMaskWording) {
      wording = MetaMaskWording
      status = "danger"
      url = { text: "https://metamask.io/", link: "https://metamask.io/" }
    }

    if (message === insufficientFuruToken) {
      wording = insufficientFuruToken
      status = "danger"
    }

    if (message === generalErrorWording) {
      wording = generalErrorWording
      status = "danger"
    }

    if (message === NoAddressWording) {
      wording = NoAddressWording
      status = "danger"
    } else {
      wording = message
      status = toastState.status
    }
    setState((prev) => ({ ...prev, message: wording, status, url }))
  }, [toastState])
  useEffect(() => {
    let timeout1, timeout2
    if (isToast) {
      if (toastState?.status !== "loading") {
        timeout1 = setTimeout(() => {
          closeToast({
            isToast: false,
            toastState: { ...toastState },
          })
        }, toastTime)
        timeout2 = setTimeout(() => {
          closeToast({
            isToast: false,
            toastState: { message: "", status: "" },
          })
        }, toastTime + 1000)
      }
    } else {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
    }
  }, [isToast])
  return (
    <>
      {/* {state?.message ? ( */}
      <div className="toast-wrapper">
        <div
          className={`toast-content-wrapper ${state?.status} ${
            !isToast ? "hide" : "show"
          }`}
        >
          <div className={`toast-state ${!isToast ? "hide" : "show"}`}>
            <div className="close-toast" onClick={handleClickClose}>
              <i className="ail ai-times pointer" />{" "}
            </div>
            <span>{state?.message} </span>
            {state?.url ? (
              <div className="mt-2">
                <Link className="toast-link" to={state?.url?.link}>
                  {state?.url?.text}
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* ) : null} */}
    </>
  )
}

export default Toast
