/* eslint-disable react-hooks/exhaustive-deps */
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector"
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector"
import { injected, walletconnect } from "components/utils/connecters"
import Cookies from "js-cookie"
import { useContext, useEffect, useState } from "react"
import { useToast } from "./hooks-custom"
import useEagerConnect from "./useEagerConnect"
import useInactiveListener from "./useInactiveListener"

const useConnectWallet = ({ isUsingHooks }) => {
  const { toast } = useToast()
  const { connector, account, activate, error, deactivate } = useWeb3React()
  const triedEagerConnect = useEagerConnect()

  const [activatingConnector, setActivatingConnector] = useState()

  useInactiveListener(!triedEagerConnect || !!activatingConnector)

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  useEffect(() => {
    error && isUsingHooks && getErrorMessage(error)
  }, [error])

  // useEffect(() => {
  //   if (!account && Cookies.get(process.env.NEXT_PUBLIC_ACCESS_TOKEN) && Cookies.get(process.env.NEXT_PUBLIC_WALLET_ADDRESS)) {
  //     Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
  //     window.location.reload()
  //   }
  // }, [account])

  const getErrorMessage = (error) => {
    if (error instanceof NoEthereumProviderError) {
      toast.error({
        message:
          "No Ethereum browser extension detected. Install MetaMask on desktop or use MetaMask app browser on mobile.",
      })
    } else if (error instanceof UnsupportedChainIdError) {
      toast.error({ message: "You're connected to an unsupported network." })
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      toast.error({
        message:
          "Please authorize this website to access your Ethereum account.",
      })
    } else if (
      error?.message &&
      error.message.includes("'wallet_requestPermissions' already pending")
    ) {
      toast.error({ message: "Metamask already open" })
    } else {
      toast.error({
        message:
          "An unknown error occurred. Check the console for more details.",
      })
    }
  }

  const onConnect = async (connector) => {
    try {
      await activate(connector)
    } catch (error) {
      return error
    }
  }

  return { account, error, onConnect, onDisconnect: deactivate }
}

export default useConnectWallet
