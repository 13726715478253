/* eslint-disable @next/next/no-img-element */
// ░█▀▀▀ ░█▀▀█ ░█▀▀▀ ░█▀▀▀ 　 ▀▀█▀▀ ░█▀▀▀█ 　 ░█▀▀▀ ░█▀▀▄ ▀█▀ ▀▀█▀▀
// ░█▀▀▀ ░█▄▄▀ ░█▀▀▀ ░█▀▀▀ 　 ─░█── ░█──░█ 　 ░█▀▀▀ ░█─░█ ░█─ ─░█──
// ░█─── ░█─░█ ░█▄▄▄ ░█▄▄▄ 　 ─░█── ░█▄▄▄█ 　 ░█▄▄▄ ░█▄▄▀ ▄█▄ ─░█──
// The component is made to be edited. However, please edit the styling on .navbar-custom.scss.

// ========================================================================================================================================
// Navbar
// ========================================================================================================================================

import React, { useState, useEffect } from "react"
import { Link, Button } from "components/anti"

import DesktopMenu from "./components/desktop-menu"

import logoDark from "assets/img/common/logo_main-dark.png"
import logoLight from "assets/img/common/logo_main-light.png"

// import imgAccount from "assets/img/common/ic_profile.png"
import imgAccount from "public/img/common/img-account.png"
import imgBurgerBar from "public/img/common/img-burger-bar.png"

// /img/common/img_token.png

import { INFO_CENTER_LINK, PLAYAREA_LINK, SHOP_LINK } from "components/page_url"
import MobileMenu from "./components/mobile-menu"
import AccountMenu from "./components/account-menu"
import { useMenuStore } from "store"
import { ConnectWallet } from "components/global/modal-connect-wallet"
import Cookies from "js-cookie"
import ModalConnect from "components/global/modal-connect-wallet"

export const Navbar = ({ handleOpenMobileMenu }) => {
  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "lg"

  // Default theme
  // Options: "navbar-dark", "navbar-light"
  const navTheme = "navbar-dark"

  // Default position
  // Options: "fixed-top", "absolute-top", ""
  const navPosition = "fixed-top"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, edit const navbarExpand to ""
  const [data, setData] = useState([
    { title: "TOKEN SHOP", url: SHOP_LINK },
    { title: "PLAY AREA", url: PLAYAREA_LINK, isLogin: true },
    { title: "INFO CENTER", url: INFO_CENTER_LINK },
  ])
  const { mobileMenu, accountMenu, setMobileMenu, setAccountMenu } =
    useMenuStore((state) => state)

  // SCROLL
  // On scroll state
  const [navScroll, setNavScroll] = useState(false)

  const handleMenuMobile = () => {
    // handleOpenMobileMenu();
    setMobileMenu(true)
  }

  const handleAccountMenu = () => {
    setAccountMenu(true)
  }

  const modalConnectId = "modal-connect-id"
  const [showModalConnect, setShowModalConnect] = useState(null)

  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect

  useIsomorphicLayoutEffect(() => {
    if (window.pageYOffset > 5) {
      setNavScroll(true)
    }
  }, [])

  useEffect(() => {
    const html = document.querySelector("html")
    const body = document.querySelector("body")

    if (mobileMenu || accountMenu) {
      body.classList.add("no-scroll")
      html.classList.add("no-scroll")
    } else {
      body.classList.remove("no-scroll")
      html.classList.remove("no-scroll")
    }
  }, [mobileMenu, accountMenu])

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 5) {
        setNavScroll(true)
      } else {
        setNavScroll(false)
      }
    }
  }, [])

  //ModalConnect

  return (
    <nav
      className={`
        navbar ${navTheme} navbar-expand-${navExpand} ${navPosition} transparent ${
        navScroll ? `is-scrolled` : ``
      }
      `}
    >
      <ConnectWallet
        id={modalConnectId}
        show={showModalConnect}
        hide={() => setShowModalConnect(null)}
      />
      <div className="navbar-main">
        <div className="container">
          <ul className="col-left">
            <li className="nav-item-brand">
              {/* prettier-ignore */}
              <Link className="navbar-brand absolute" to="/">
                <img src={logoLight} className="logo-light img-fluid" alt="Logo" />
                <img src={logoDark} className="logo-dark img-fluid" alt="Logo" />
              </Link>
            </li>
          </ul>

          {/* Mobile */}
          <ul className={`col-right d-${navExpand}-none`}>
            <li className="nav-item nav-menu-icon ">
              <img
                src={imgAccount}
                className="img-fluid nav-img-icon"
                alt="nav-img-icon"
                width="35px"
                onClick={
                  Cookies.get(process.env.NEXT_PUBLIC_ACCESS_TOKEN)
                    ? handleAccountMenu
                    : () => setShowModalConnect(modalConnectId)
                }
              />
            </li>
            <li className="nav-item nav-menu-icon">
              <img
                src={imgBurgerBar}
                className="img-fluid nav-img-icon"
                alt="nav-img-icon"
                width="35px"
                onClick={handleMenuMobile}
              />
            </li>
          </ul>

          {/* Desktop */}
          <DesktopMenu
            openConnect={() => setShowModalConnect(modalConnectId)}
            data={data}
            navExpand={navExpand}
          />
        </div>
        <MobileMenu handleOpenMobileMenu={handleOpenMobileMenu} />
        <AccountMenu handleAccountMenu={handleAccountMenu} />
      </div>
      {/* <div className="menu-bg" /> */}
    </nav>
  )
}
